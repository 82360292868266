import React from "react"

const WhatIsIncluded = () => (
    <div id="what" className="section dark" >
    <div className="container">
        <div className="row sectonTitle">
            <div className="col"><h2>Included With Each Package</h2></div>
        </div>
        <div className="row sectonContent">
            <div className="col-lg-6">
                <div className="row"><div className="col"><h4>Blazing Fast Performance</h4></div></div>
                <div className="row"><div className="col">
                    <p className="sectionText">
                        We use advanced pre-rendering techniques to create high-performance websites that have been proven to increase conversion rates. 
                    </p>
                </div></div>
            </div>
            <div className="col-lg-6">
                <div className="row"><div className="col"><h4>Mobile Responsiveness</h4></div></div>
                <div className="row"><div className="col">
                    <p className="sectionText">
                        Our sites look good on any screen so your customers will get the same great experience no matter what device they are using. 
                    </p>
                </div></div>
            </div>
            
        </div>
        <div className="row sectonContent">
            <div className="col-lg-6">
                <div className="row"><div className="col"><h4>Google Analytics</h4></div></div>
                <div className="row"><div className="col">
                    <p className="sectionText">
                        GA is integrated into each website we deliver. As a standard in web analytics, you can expect comprehensive data and reporting tools to help gain insight on site performance, customer behaver and much more.
                    </p>
                </div></div>
            </div>
            <div className="col-lg-6">
                <div className="row"><div className="col"><h4>Transparent Pricing</h4></div></div>
                <div className="row"><div className="col">
                    <p className="sectionText">
                        Simple and straight forward pricing with no hidden costs. Each website package has a one-time setup price and a monthly hosting fee. Our no non-sense pricing model extends to all other services as well. 
                    </p>
                </div></div>
            </div>
        </div>
    </div>   
    </div> 
)
  
  export default WhatIsIncluded