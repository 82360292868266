import React from "react"
import Layout from "../components/layout"
import ContactUs from '../components/contact'
import WhatIsIncluded from '../components/what'
import SEO from "../components/seo"

const PricingPage = () => (
  <Layout page="pricing">
    <SEO title="Rethink Main - Pricing" />
    <WhatIsIncluded />
    <ContactUs />
  </Layout>
)

export default PricingPage